const styles = (theme) => ({
    // root: {
    //     maxWidth: 345,
    // },
    // media: {
    //     height: 140,
    // },
    root: {
        minWidth: 345,
        maxWidth: 345,
    },
    media: {
        height: 345,
    },
});

export default styles;